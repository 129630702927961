<template>
  <v-container class="mt-5 mb-15 px-10" fluid>
    <PageHeader
      :header-text="plugin ? 'Update ' : 'Install' + ' Twilio plugin'"
    >
      <template #subheader>
        This plugin allows sending SMS messages from Twilio and saves SMS actions.
      </template>
    </PageHeader>

    <div class="mt-10">
      <validation-observer
        ref="inputRef"
      >
        <div>
          <CustomTextInput
            v-model="accountSid"
            header="Account SID"
            required
          />
        </div>
        <div>
          <CustomTextInput
            v-model="authToken"
            type="password"
            header="Auth Token"
            required
          />
        </div>
        <template v-if="plugin">
          <v-alert
            v-if="credentialsChanged"
            type="error"
            class="mt-2"
            style="font-size: 12px;"
          >
            <strong>WARNING:</strong> By changing the credentials, you are changing the Twilio account this plugin works with.<br>
            Please be aware that the following will happen if you save these changes all webhooks for this account will be removed from the old Twilio account.
          </v-alert>
          <div>
            <CustomDropdown
              v-model="phoneNumber"
              header="Outbound SMS Phone Number"
              hint="This is the phone number used to send outbound SMS messages."
              :items="phoneNumbers"
              return-object
              autocomplete
              item-text="phoneNumber"
            />
            <v-alert
              v-if="phoneNumber && phoneNumber.sid"
              class="mt-3"
              style="font-size: 14px;"
              outlined
              :type="webhookStatus.type"
              text
            >
              Webhook Status: {{ webhookStatus.text }}
            </v-alert>
            <CustomTextInput
              v-model="optOutKeywords"
              header="Opt Out Keywords"
              hint="This is a comma-separated list of opt-out keywords. When Pulse receives one of these keywords in a message, it will opt-out the contact's phone number from future SMS messages. Note: By default, Twilio and Pulse will handle the following keywords: STOP, STOPALL, UNSUBSCRIBE, CANCEL, END, QUIT"
            />
            <CustomTextInput
              v-model="optInKeywords"
              header="Opt In Keywords"
              hint="This is a comma-separated list of opt-in keywords. When Pulse receives one of these keywords in a message, it will make sure the contact's phone number is subscribed to receive SMS messages. Note: By default, Twilio and Pulse will handle the following keywords: START, YES, UNSTOP"
            />
          </div>
        </template>
      </validation-observer>
      <v-row class="mt-12">
        <v-col cols="12" class="d-flex justify-end">
          <v-btn
            v-if="installed"
            class="custom-button custom-button--red-text px-8 mr-4"
            height="34px"
            depressed
            @click="deactivateDialog = true;"
          >
            Deactivate
          </v-btn>

          <v-btn
            class="custom-button custom-button--blue px-13"
            height="34px"
            depressed
            :block="$vuetify.breakpoint.xsOnly"
            @click="trigger"
          >
            {{ plugin ? 'Update' : 'Install' }}
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <DeactivateConfirmDialog
      v-if="deactivateDialog"
      :id="id"
      :plugin="plugin"
      @dismiss="deactivateDialog = false;"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import DeactivateConfirmDialog from "./components/DeactivateConfirmDialog";

export default {
  name: "AddPluginTwilio",
  metaInfo: {
    title: 'Install Twilio'
  },
  components: {
    CustomTextInput,
    CustomDropdown,
    PageHeader,
    DeactivateConfirmDialog,
  },
  props: {
    id: {
      default: () => null,
      type: [Number, String],
    },
    installed: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    deactivateDialog: false,
    accountSid: null,
    authToken: null,
    plugin: null,
    phoneNumber: null,
    phoneNumbers: [],
    optInKeywords: null,
    optOutKeywords: null,
    oldAccountSid: null,
    oldAuthToken: null,
  }),
  computed: {
    credentialsChanged() {
      return !!this.plugin && (this.oldAccountSid !== this.accountSid || this.oldAuthToken !== this.authToken);
    },
    webhookStatus() {
      const smsUrl = this.phoneNumber?.smsUrl;

      if (!smsUrl) {
        return {
          type: 'info',
          text: 'The phone number will be updated with the correct webhook URL when you save the plugin.',
        };
      }
      if (smsUrl !== this.webhookUrl) {
        return {
          type: 'error',
          text: 'The phone number currently has a webhook URL configured. If you save this plugin, it will be overwritten. Please confirm before continuing to avoid unexpected behavior. Current URL: ' + smsUrl,
        };
      }

      return {
        type: 'success',
        text: 'The phone number is currently configured with the correct webhook URL.',
      };
    },
    webhookUrl() {
      const selectedAccount = this.$store.getters["user/account"];

      return process.env.VUE_APP_API_URL + '/plugin/twilio/inbound/' + selectedAccount.accountId;
    },
  },
  async created() {
    if (this.installed) {
      await this.loadAccountPlugin();
    }
  },
  methods: {
    async loadAccountPlugin() {
      const response = await this.$rest.plugins.get_resource(this.id, {
        includeCredentials: true,
      });
      if (!response.data) {
        return;
      }
      this.plugin = {...response.data};
      this.accountSid = this.plugin?.pluginConfiguration?.credentials?.accountSid;
      this.authToken = this.plugin?.pluginConfiguration?.credentials?.authToken;
      this.oldAccountSid = this.accountSid;
      this.oldAuthToken = this.authToken;

      if (this.accountSid && this.authToken) {
        await this.loadPhoneNumbers();
      }

      const phoneSid = this.plugin?.pluginConfiguration?.phoneSid ?? null;

      this.phoneNumber = this.phoneNumbers.find(p => p?.sid === phoneSid);
      this.optOutKeywords = this.plugin?.pluginConfiguration?.optOutKeywords;
      this.optInKeywords = this.plugin?.pluginConfiguration?.optInKeywords;
    },
    async loadPhoneNumbers() {
      this.phoneNumbers = (await this.$rest.plugins.twilio.getNumbers()).data;
    },
    trigger() {
      if ( this.accountSid === null || this.accountSid === '' ) {
        return this.$store.commit('snackbar/showMessage', {
          content: 'Account SID is required.',
          color: 'error',
        });
      }
      if ( this.authToken === null || this.authToken === '' ) {
        return this.$store.commit('snackbar/showMessage', {
          content: 'Auth Token is required.',
          color: 'error',
        });
      }
      if (this.plugin) {
        return this.updatePlugin();
      } else {
        return this.installPlugin();
      }
    },
    async updatePlugin() {
      const pluginConfiguration = {
        credentials: {
          accountSid: this.accountSid,
          authToken: this.authToken,
        },
        phoneNumber: this.phoneNumber?.phoneNumber,
        phoneSid: this.phoneNumber?.sid,
        optOutKeywords: this.optOutKeywords,
        optInKeywords: this.optInKeywords,
      };

      const response = await this.$rest.plugins.put_resource(this.id, {
        ...this.plugin,
        plugin: this.plugin.plugin.id,
        pluginConfiguration
      });
      if (!response.data) {
        return this.$store.commit('snackbar/showMessage', {
          content: 'Failed to update plugin',
          color: 'error',
        });
      }

      // Reload the selected account so that the campaign page gets updated
      const selectedAccount = this.$store.getters["user/account"];
      await this.$store.dispatch(
        'user/selectAccount',
        selectedAccount.accountId,
        selectedAccount.logo
      );

      this.$store.commit('snackbar/showMessage', {
        content: 'Plugin updated successfully',
        color: 'info',
      });
      setTimeout(() => {
        this.$router.push({
          name: "Plugins",
        })
      }, 500);
    },
    async installPlugin() {
      const pluginConfiguration = {
        credentials: {
          accountSid: this.accountSid,
          authToken: this.authToken
        },
      };
      try {
        const response = await this.$rest.plugins.post_resource({
          plugin: +this.id,
          pluginConfiguration,
        });
        if (!response.data) {
          return this.$store.commit('snackbar/showMessage', {
            content: 'Failed to install plugin',
            color: 'error',
          });
        }

        this.$store.commit('snackbar/showMessage', {
          content: 'Installed plugin successfully',
          color: 'info',
        });
        setTimeout(() => {
          this.$router.push({
            name: "AddPluginTwilio",
            params: {
              id: response.data.id,
            },
            query: {
              installed: true,
            }
          });
        }, 500);
      } catch (e) {
        const errorResponse = e.response?.data ?? null;
        if (errorResponse) {
          const firstError = errorResponse.errors?.[0] ?? null;

          if (firstError) {
            return this.$store.commit('snackbar/showMessage', {
              'content': firstError.error,
              'color': 'error',
            });
          }
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .delete-icon {
    cursor: pointer;
  }

.field-subheader {
  font-family: Open Sans,sans-serif;
  line-height: normal;
  color: #66788e;
  font-size: 13px;
}
</style>
